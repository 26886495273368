// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../routers/route-names';
import layoutClasses from '../../styles/layout.module.less';
import { loginSelector } from '../login/store/selectors';
import { logoutAction } from '../login/store/actions';
import { useGetMappedTrendDataChartData } from '../dashboard/store/hooks';
import { QueryParamKey } from '../login/constants';

export const Logout: React.FC = function Logout() {
  const dispatch = useDispatch();
  const { tenantId, hasValidSession } = useSelector(loginSelector);
  const { clearTimePeriodOption } = useGetMappedTrendDataChartData({});

  const [tenantIdInURL, setTenantIdInURL] = useState<string | null>(null);

  useEffect(() => {
    if (tenantId == null) {
      try {
        setTenantIdInURL(
          new URL(window.location.href).searchParams.get(
            QueryParamKey.TENANT_ID,
          ),
        );
      } catch (e) {}
    }
  }, []);

  const isLogoutFinished = !hasValidSession;
  const isTenantIdStored = typeof tenantId === 'string';

  React.useEffect(() => {
    dispatch(logoutAction.request());
    clearTimePeriodOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Layout
      data-testid="logout"
      className={clsx(
        layoutClasses.mainContainer,
        layoutClasses.centeredContent,
      )}
    >
      <Spin />
      {(isTenantIdStored || tenantIdInURL != null) && isLogoutFinished && (
        <Redirect to={`${LOGIN_ROUTE}?tenantId=${tenantId ?? tenantIdInURL}`} />
      )}
    </Layout>
  );
};

export default Logout;
